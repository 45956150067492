import { CssBaseline, ThemeProvider } from "@mui/material";
import { PropsWithChildren } from "react";
import themeLight from "./themeLight";

export default function ThemeWrapper({ children }: PropsWithChildren) {
  return (
    <ThemeProvider theme={themeLight}>
      <CssBaseline enableColorScheme />
      {children}
    </ThemeProvider>
  );
}

import { Divider } from "@mui/material";
import { useHashScroll } from "hooks";
import { PublicLayout } from "layouts";
import { ContactUs, HowItWorks, Main } from "sections";

export default function Home() {
  useHashScroll();

  return (
    <PublicLayout>
      <Main />
      <Divider variant="inset" />
      <HowItWorks />
      <ContactUs />
    </PublicLayout>
  );
}

import { ArrowRightAlt } from "@mui/icons-material";
import { Box, Container, Typography } from "@mui/material";
import { StyledBtn } from "components";
import { Link } from "react-router-dom";

export default function Main() {
  return (
    <Container component="section" id="main-section">
      <Typography
        variant="h2"
        color="primary.main"
        width="100%"
        textAlign="center"
        mt={15}
      >
        Personalized health guidance,
      </Typography>
      <Typography
        variant="h2"
        color="secondary.main"
        width="100%"
        textAlign="center"
      >
        at your fingertips
      </Typography>
      <Box width="100%" textAlign="center" mt={5}>
        <StyledBtn
          color="primary"
          variant="contained"
          LinkComponent={Link}
          {...{ to: "/#contact-us" }}
          endIcon={<ArrowRightAlt />}
        >
          Book a Free Demo
        </StyledBtn>
      </Box>
      <Box width="100%" textAlign="center" mt={5}>
        <Box
          component="img"
          mx="auto"
          width={{ xs: "100%", sm: "80%" }}
          src="/assets/main/Blue Black Modern Shoes Product Features Infographic Instagram Post (1).png"
        />
      </Box>
      {/* <Typography
        variant="body2"
        color="primary.main"
        width="100%"
        textAlign="center"
        mt={1}
        mb={1}
      >
        Get your <b>free account today</b>
      </Typography>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        spacing={2}
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <StyledBtn color="primary" variant="contained" startIcon={<Google />}>
          Sign up with Google
        </StyledBtn>
        <Typography variant="subtitle1" color="gray" textAlign="center">
          or
        </Typography>
        <StyledBtn
          color="secondary"
          variant="contained"
          endIcon={<ArrowRightAlt />}
        >
          Sign up with Email
        </StyledBtn>
      </Stack> 
      <Typography
        variant="subtitle2"
        color="gray"
        width="100%"
        textAlign="center"
        mt={3}
        mb={1}
      >
        No credit card required
      </Typography> */}
    </Container>
  );
}

import { Palette } from "@mui/material";
import { TypographyOptions } from "@mui/material/styles/createTypography";

export const typography: (palette: Palette) => TypographyOptions = () => ({
  allVariants: {
    fontFamily: "Circular Srd Book, sans-serif"
  },
  h1: {
    fontSize: 48,
    fontWeight: "bold"
  },
  h2: {
    fontSize: 60,
    fontWeight: "bold"
  },
  h3: {
    fontSize: 20,
    fontWeight: "bold",
  },
  h4: {
    fontWeight: "bold",
  },
  h5: {
    fontWeight: "bold",
  },
  h6: {
    fontWeight: "bold",
  },
  caption: {
    fontSize: 18
  }
});

export default typography;

import { Components, Theme } from "@mui/material";
export const components: Components<Omit<Theme, "components">> = {
  MuiCssBaseline: {
    styleOverrides: {
      "@font-face": {
        fontFamily: "Circular Srd Book",
        src: `url('/assets/fonts/FontsFree-Net-Circular-Std-Book.ttf') format("truetype")`,
      },
      body: {
        fontFamily: "Circular Srd Book, sans-serif",
      },
    },
  },
  MuiTooltip: {
    defaultProps: {
      placement: "top",
    },
  },
  MuiCircularProgress: {
    defaultProps: {
      size: "17px",
    },
    styleOverrides: {
      root: {
        color: "inherit",
      },
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        marginLeft: 0,
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        minWidth: "max-content",
      },
    },
  },
  MuiCard: {
    variants: [
      {
        props: {
          variant: "outlined",
        },
        style: ({ theme }) => {
          const palette: any = theme.palette;
          return {
            border: 0,
            height: "100%",
            backgroundColor: palette.secondary["50"],
          };
        },
      },
    ],
  },
  MuiInputLabel: {
    styleOverrides: {
      root: ({ theme: { palette } }) => ({
        color: palette.primary.light,
      }),
    },
  },
};
export default components;

import { Button, styled } from "@mui/material";

const NavBtn = styled(Button)(({ theme: { transitions, palette } }) => ({
  color: palette.primary.main,
  marginLeft: 10,
  cursor: "pointer",
  ":hover": {
    color: palette.secondary.main,
    backgroundColor: "transparent",
  },
  textTransform:"none",
  backgroundColor: "transparent",
  border: 0,
  padding: 0,
  fontSize: 16,
  fontWeight: "bold",
  textDecoration: "none",
  transitions: transitions.create("color", {
    duration: transitions.duration.standard,
    easing: transitions.easing.easeIn,
  }),
  '& .MuiButton-endIcon': {
    margin: 0, // Remove the default margin applied to endIcon
  },
}));
export default NavBtn;

import { createTheme } from "@mui/material";
import COLORS from "./colors";
import components from "./components";
import { getColorVariations } from "./config";
import typography from "./typography";

const themeLight = createTheme({
  typography,
  components,
  palette: {
    mode: "light",
    primary: {
      ...getColorVariations(COLORS.primary.main),
      dark: COLORS.primary.dark,
      light: COLORS.primary.light,
      contrastText: COLORS.white
    },
    secondary: {
      ...getColorVariations(COLORS.secondary.main),
      dark: COLORS.secondary.dark,
      light: COLORS.secondary.light,
      contrastText: COLORS.white
    },
    text: {
      primary: COLORS.primary.dark,
      secondary: COLORS.white,
    },
    common: {
      black: COLORS.black,
      white: COLORS.white,
    },
  },
});
export default themeLight;

import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function useHashScroll() {
  const location = useLocation();
  useEffect(() => {
    const { hash } = location;
    const elem = document.getElementById(hash.replace("#", ""));
    if (elem) {
      elem.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    }
  }, [location]);
}

import { Box, styled } from "@mui/material";

const CollapseHeader = styled(Box)(({ theme: { transitions, palette } }) => ({
  display: "flex",
  width: "100%",
  alignItems: "center",
  justifyContent: "space-between",
  cursor: "pointer",
}));
export default CollapseHeader;

import { Box, Container, Grid, Typography } from "@mui/material";
import { Fragment } from "react";

const HOW_IT_WORKS = [
  {
    id: "use-case-1",
    image: "/assets/use-cases/4.png",
    title:
      "MedAide can swiftly guide patients and doctors in providing medical prescriptions and suggestions.",
    subtitle:
      " Choose from emails, social posts, long-form blog posts, and more!",
  },
  {
    id: "use-case-2",
    image: "/assets/use-cases/5.png",
    title:
      "MedAide provides detailed guidance and treatment methodology for any disorder.",
    subtitle:
      " Choose from emails, social posts, long-form blog posts, and more!",
  },
  {
    id: "use-case-3",
    image: "/assets/use-cases/6.png",
    title:
      "MedAide also does Differential Diagnosis (DDX) based on symptoms of many diseases.",
    subtitle:
      " Choose from emails, social posts, long-form blog posts, and more!",
  },
];

export default function HowItWorks() {
  return (
    <Container component="section" id="how-it-works">
      <Typography
        variant="h1"
        color="primary.main"
        width="100%"
        textAlign="center"
        my={10}
      >
        How it works
      </Typography>
      <Grid container spacing={1}>
        {HOW_IT_WORKS.map(({ id, image, subtitle, title }, i) => {
          const iteration = i + 1;
          const orderFirst = (i + 1) * iteration;
          const orderSecond = (i + 2) * iteration;
          // const orderThird = (i + 3) * iteration;
          return (
            <Fragment key={i}>
              <Grid
                item
                xs={6}
                order={i % 2 ? orderSecond : orderFirst}
                id={id}
              >
                <Box component="img" src={image} width="100%" />
              </Grid>
              <Grid item xs={6} order={i % 2 ? orderFirst : orderSecond}>
                <Box
                  height="100%"
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography
                    px={1}
                    variant="caption"
                    textAlign={i % 2 ? "end" : undefined}
                  >
                    {title}
                  </Typography>
                  {/* <Stack direction="row" spacing={1}>
                    <Avatar
                      sx={{
                        backgroundColor: "secondary.100",
                        color: "primary.main",
                      }}
                    >
                      {i + 1}
                    </Avatar>
                    <Box>
                      
                      <Typography>{subtitle}</Typography>
                    </Box>
                  </Stack> */}
                </Box>
              </Grid>
              {/* {i < arr.length - 1 && (
                <Grid item xs={12} order={orderThird}>
                  <Box width="100%" textAlign="center">
                    <Box
                      width={{ xs: 200, sm: 300 }}
                      component="img"
                      src="/assets/svgs/4.svg"
                      sx={{ transform: i % 2 && "scaleX(-1)" }}
                    />
                  </Box>
                </Grid>
              )} */}
            </Fragment>
          );
        })}
      </Grid>
    </Container>
  );
}

export const COLORS = {
  white: "#fff",
  black: "#000",
  primary: {
    light: "#215c9a",
    main: "#23619b",
    dark: "#225e9b",
  },
  secondary: {
    light: "#40a9db",
    main: "#5ec6c7",
    dark: "#3fbed5",
  },
};
export default COLORS;

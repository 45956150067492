import { styled } from "@mui/material";
import { Link } from "react-router-dom";

const NavItem = styled(Link)(({ theme: { transitions, palette } }) => ({
  color: palette.primary.main,
  marginInline: "10px !important",
  cursor: "pointer",
  ":hover": {
    color: palette.secondary.main,
  },
  textDecoration: "none",
  fontSize: 16,
  fontWeight: "bold",
  transitions: transitions.create("color", {
    duration: transitions.duration.standard,
    easing: transitions.easing.easeIn,
  }),
}));
export default NavItem;

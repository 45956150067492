import { Fragment } from "react";

const MyError: React.FC = () => {
  return (
    <Fragment>
      <p>There has been some error</p>
      <button onClick={() => window.location.reload()}>Refresh</button>
    </Fragment>
  );
};

export default MyError;

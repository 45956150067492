import { Box, Typography } from "@mui/material";

export default function Footer() {
  return (
    <Box
      component="footer"
      id="footer"
      width="100%"
      textAlign="center"
      bgcolor="secondary.100"
      position="static"
      py={3}
      mt={2}
      mb={0}
    >
      <Typography>© Copyright MedAide. All Rights Reserved</Typography>
    </Box>
  );
}

import {
  ChevronLeft,
  ExpandMore,
  KeyboardArrowUp,
  Menu as MenuIcon,
} from "@mui/icons-material";
import {
  AppBar,
  Box,
  Collapse,
  Container,
  Drawer,
  IconButton,
  Menu,
  Stack,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  CollapseHeader,
  DrawerHeader,
  Main,
  NavBtn,
  NavItem,
  StyledBtn,
} from "components";
import { Fragment, PropsWithChildren, useState } from "react";
import { Footer } from "sections";
import { LabelValue } from "types";

interface INav {
  label: string;
  value?: string;
  children?: LabelValue<string>[];
}

const NAVIGATION: INav[] = [
  {
    label: "Chat",
    value: "/#contact-us",
  },
  {
    label: "Use Cases",
    children: [
      {
        label: "Medical Prescriptions",
        value: "/#use-case-1",
      },
      {
        label: "Treatment Suggestions",
        value: "/#use-case-2",
      },
      {
        label: "Differential Diagnosis (DDX)",
        value: "/#use-case-3",
      },
    ],
  },
  {
    label: "Pricing",
    value: "/#",
  },
];
const END_NAVIGATION: INav[] = [
  {
    label: "Contact Sales",
    value: "/#contact-us",
  },
  {
    label: "Login",
    value: "/#",
  },
];
const MenuItems = ({
  label,
  items,
}: {
  label?: string;
  items: LabelValue<string>[];
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Fragment>
      <NavBtn
        onClick={handleClick}
        endIcon={
          <KeyboardArrowUp
            sx={({ transitions }) => ({
              transform: !open ? "rotate(180deg)" : undefined,
              transition: transitions.create("transform", {
                duration: transitions.duration.standard,
                easing: transitions.easing.sharp,
              }),
            })}
          />
        }
      >
        {label}
      </NavBtn>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <Stack direction="column" spacing={1}>
          {items.map(({ label, value }, i) => (
            <NavItem to={value} onClick={handleClose} key={i}>
              {label}
            </NavItem>
          ))}
        </Stack>
      </Menu>
    </Fragment>
  );
};
const CollapseItems = ({
  label,
  items,
}: {
  label?: string;
  items: LabelValue<string>[];
}) => {
  const [expanded, setExpanded] = useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  return (
    <Fragment>
      <CollapseHeader onClick={handleExpandClick}>
        <NavItem to="">{label}</NavItem>
        <ExpandMore
          sx={({ transitions }) => ({
            transform: expanded ? "rotate(180deg)" : undefined,
            transition: transitions.create("transform", {
              duration: transitions.duration.standard,
              easing: transitions.easing.sharp,
            }),
          })}
        />
      </CollapseHeader>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Stack direction="column" spacing={1}>
          {items.map(({ label, value }, i) =>
            value ? (
              <NavItem to={value} key={i}>
                {label}
              </NavItem>
            ) : null
          )}
        </Stack>
      </Collapse>
    </Fragment>
  );
};

export default function PublicLayout({ children }: PropsWithChildren) {
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.up("md"));
  const [open, setOpen] = useState(false);

  const getNavigation = (navigation: INav[], collapse?: boolean) => {
    return navigation.map(({ label, value, children }, i) => {
      return value ? (
        <NavItem to={value} key={i}>
          {label}
        </NavItem>
      ) : children ? (
        collapse ? (
          <CollapseItems label={label} items={children} key={i} />
        ) : (
          <MenuItems label={label} items={children} key={i} />
        )
      ) : null;
    });
  };

  return (
    <Fragment>
      <AppBar
        position="fixed"
        variant="outlined"
        elevation={0}
        sx={{ backgroundColor: "white", border: 0 }}
      >
        <Container>
          <Toolbar
            sx={{ justifyContent: "space-between", alignItems: "center" }}
            variant="dense"
          >
            <Stack direction="row" spacing={1} alignItems="center">
              <IconButton
                color="primary"
                aria-label="open drawer"
                onClick={() => setOpen(true)}
                edge="start"
                sx={{ mr: 2, display: { md: "none" } }}
              >
                <MenuIcon />
              </IconButton>
              <Box
                component="img"
                src="/assets/logo/Logo.png"
                width={50}
                p={1}
              />
              <Typography variant="h5" color="secondary.main" pr={1}>
                MedAide
              </Typography>
              {isMediumScreen && getNavigation(NAVIGATION)}
            </Stack>
            {isMediumScreen && (
              <Stack direction="row" spacing={1} alignItems="center">
                {getNavigation(END_NAVIGATION)}
                <StyledBtn
                  sx={{ py: 1.25 }}
                  variant="contained"
                  color="secondary"
                >
                  Get Started Free
                </StyledBtn>
              </Stack>
            )}
          </Toolbar>
        </Container>
      </AppBar>
      <Drawer
        anchor="left"
        open={open && !isMediumScreen}
        onClose={setOpen}
        sx={{
          width: 240,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: 240,
            boxSizing: "border-box",
            padding: 1,
          },
        }}
      >
        <DrawerHeader>
          <Box component="img" src="/assets/logo/Logo.png" width={50} p={1} />
          <Typography variant="h5" color="secondary.main">
            MedAide
          </Typography>
          <IconButton onClick={() => setOpen(false)} color="primary">
            <ChevronLeft />
          </IconButton>
        </DrawerHeader>
        <Stack direction="column" spacing={1}>
          {getNavigation([...NAVIGATION, ...END_NAVIGATION], true)}
          <StyledBtn variant="contained" color="secondary">
            Get Started Free
          </StyledBtn>
        </Stack>
      </Drawer>
      <DrawerHeader />
      <Main>{children}</Main>
      <Footer />
    </Fragment>
  );
}

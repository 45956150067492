import { Button, styled } from "@mui/material";

const StyledBtn = styled(Button)(() => ({
  paddingBlock: 15,
  paddingInline: 25,
  borderRadius: 20,
  fontWeight: "bold",
  fontFamily: "Circular Srd Book"
}));
export default StyledBtn;

import {
  LocalPhone,
  LocationOnOutlined,
  MailOutline,
  WatchLaterOutlined,
} from "@mui/icons-material";
import {
  Card,
  CardActions,
  CardContent,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { StyledBtn, StyledLink } from "components";
import { useSnackbar } from "notistack";
import { useCallback, useState } from "react";
import { getFormsLink } from "utils/getters";

const CONTACT_US = [
  {
    icon: <LocationOnOutlined fontSize="large" sx={{ p: 0 }} />,
    title: "Address",
    subtitle1: "Islamabad,Pakistan",
    link: "https://goo.gl/maps/owjNC24QMFc9U8yD8",
  },
  {
    icon: <LocalPhone fontSize="large" sx={{ p: 0 }} />,
    title: "Call Us",
    subtitle1: "+92 335 5815205",
    link: "tel:+923355815205",
  },
  {
    icon: <MailOutline fontSize="large" sx={{ p: 0 }} />,
    title: "Email Us",
    subtitle1: "info@medaide.org",
    link: "mailto:info@medaide.org",
  },
  {
    icon: <WatchLaterOutlined fontSize="large" sx={{ p: 0 }} />,
    title: "Open Hours",
    subtitle1: "Monday - Friday",
    subtitle2: "9:00AM - 05:00PM (GMT+5)",
  },
];
const defaultValues = {
  name: "",
  email: "",
  subject: "",
  message: "",
};
export default function ContactUs() {
  const [state, setState] = useState(defaultValues);
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit: React.FormEventHandler<HTMLDivElement> = useCallback(
    async (e) => {
      e.preventDefault();
      try {
        const endpoint = getFormsLink(
          state.name,
          state.email,
          state.subject,
          state.message
        );
        const res = await fetch(endpoint, { method: "GET", mode: "no-cors" });
        console.log(res);
        enqueueSnackbar("Request Submitted", { variant: "success" });
      } catch (err: any) {
        enqueueSnackbar(err.message || "Failed to Submit Request", {
          variant: "error",
        });
        console.error(err);
      }
    },
    [state, enqueueSnackbar]
  );

  return (
    <Container component="section" id="contact-us">
      <Typography
        variant="h1"
        color="primary.main"
        width="100%"
        textAlign="center"
        my={10}
      >
        Book a Free Demo Now !
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6}>
          <Grid container spacing={1}>
            {CONTACT_US.map(
              ({ icon, title, subtitle1, subtitle2, link }, i) => (
                <Grid item xs={6} key={i}>
                  <Card variant="outlined">
                    <CardContent>
                      {icon}
                      <Typography variant="h3" my={1}>
                        {title}
                      </Typography>
                      {link ? (
                        <StyledLink to={link} target="_blank">
                          {subtitle1}
                        </StyledLink>
                      ) : (
                        <Typography variant="body2" color="text.disabled">
                          {subtitle1}
                        </Typography>
                      )}
                      {subtitle2 && (
                        <Typography variant="body2" color="text.disabled">
                          {subtitle2}
                        </Typography>
                      )}
                    </CardContent>
                  </Card>
                </Grid>
              )
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Card variant="outlined" component="form" onSubmit={onSubmit}>
            <CardContent>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    type="text"
                    label="Name"
                    sx={{ bgcolor: "white" }}
                    value={state.name}
                    onChange={(e) => {
                      setState((prevState) => ({
                        ...prevState,
                        name: e.target.value,
                      }));
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    type="email"
                    label="Email"
                    sx={{ bgcolor: "white" }}
                    value={state.email}
                    onChange={(e) => {
                      setState((prevState) => ({
                        ...prevState,
                        email: e.target.value,
                      }));
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    type="text"
                    label="Subject"
                    autoComplete="subtitle"
                    sx={{ bgcolor: "white" }}
                    value={state.subject}
                    onChange={(e) => {
                      setState((prevState) => ({
                        ...prevState,
                        subject: e.target.value,
                      }));
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    label="Message"
                    type="text"
                    sx={{ bgcolor: "white" }}
                    value={state.message}
                    onChange={(e) => {
                      setState((prevState) => ({
                        ...prevState,
                        message: e.target.value,
                      }));
                    }}
                    minRows={3}
                    multiline
                    fullWidth
                  />
                </Grid>
              </Grid>
            </CardContent>
            <CardActions>
              <StyledBtn variant="contained" type="submit" sx={{ mx: "auto" }}>
                Submit
              </StyledBtn>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}

import { SnackbarProvider } from "notistack";
import { ErrorBoundary, MyError } from "./components";
import { Routing } from "./routing";
import { ThemeWrapper } from "./theme";
if (process.env.NODE_ENV === "production") {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}
export default function App() {
  return (
    <ErrorBoundary fallback={<MyError />}>
      <ThemeWrapper>
        <SnackbarProvider autoHideDuration={2000}>
          <Routing />
        </SnackbarProvider>
      </ThemeWrapper>
    </ErrorBoundary>
  );
}
